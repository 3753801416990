<template>
	<el-scrollbar>
		<div class="t-platform">
			<div class="t-platform-head">
				<el-date-picker
					v-model="datetime"
					type="daterange"
					range-separator="-"
					:start-placeholder="$t('teacher.placeholder.startDate')"
					:end-placeholder="$t('teacher.placeholder.endDate')"
					value-format="timestamp"
					:picker-options="pickerOptions"
					@change="getTeacherData">
				</el-date-picker>
			</div>
			<div class="t-platform-list">
				<router-link :to="{ name: 'teacherMember' }" class="item">
					<el-badge :value="teacherData.stu_num">
						<i class="item-icon el-icon-s-custom"></i>
					</el-badge>
					<!-- <img src="" alt="" class="pic"> -->
					<p class="item-label">{{$t('teacher.tags.member')}}</p>
				</router-link>
				<router-link :to="{ name: 'teacherShop' }" class="item">
					<el-badge :value="teacherData.store_num">
						<i class="item-icon el-icon-s-shop"></i>
					</el-badge>
					<p class="item-label">{{$t('teacher.tags.shop')}}</p>
				</router-link>
				<router-link :to="{ name: 'teacherLiveField', query:{ begin_time: formatDate(datetime,'0'), end_time: formatDate(datetime,'1') }}" class="item">
					<el-badge :value="teacherData.live_num">
						<i class="item-icon el-icon-video-camera-solid"></i>
					</el-badge>
					<p class="item-label">{{$t('teacher.tags.live')}}</p>
				</router-link>
				<router-link :to="{ name: 'teacherOrder' }" class="item">
					<el-badge :value="teacherData.order_num">
						<i class="item-icon el-icon-s-order"></i>
					</el-badge>
					<p class="item-label">{{$t('teacher.tags.order')}}</p>
				</router-link>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchStatistics } from '@/api/teacher';
	import { parseTime } from '@/utils';
	export default {
		data() {
			return {
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				datetime: "",
				teacherData: {},
			}
		},
		created() {
			this.getTeacherData();
		},
		methods: {
			formatDate(time,index){
				if(time){
					return parseTime(time[index]/1000,'{y}-{m}-{d}')
				} else {
					return time
				}
			},
			getTeacherData() {
				let params = {
					uuid: this.$store.getters.uuid
				}
				if(this.datetime){
					params.begin_time = this.datetime[0]/1000;
					params.end_time = this.datetime[1]/1000;
				}
				fetchStatistics(params).then(response => {
					this.teacherData = response.data;
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.t-platform {
		width: 1000px;
		padding: 100px 0;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		.t-platform-head {
			text-align: right;
			width: 100%;
			min-height: 40px;
			margin-bottom: 20px;
		}
		.t-platform-list {
			width: 100%;
			box-sizing: border-box;
			padding: 0 60px;
			.item {
				width: calc(50% - 30px);
				padding: 30px 20px;
				border: 1px solid $--seller-primary;
				display: inline-block;
				box-sizing: border-box;
				margin: 15px;
				border-radius: 6px;
				text-align: center;
				cursor: pointer;
				.pic {
					width: 80px;
					height: 80px;
					display: block;
					margin: 0 auto;
				}
				.item-icon {
					color: $--seller-primary;
					font-size: 40px;
					padding: 20px;
					border-radius: 40px;
					border: 1px solid $--seller-primary;
				}
				.item-label {
					color: $--seller-primary;
					font-size: 16px;
					margin-top: 12px;
				}
				::v-deep .el-badge {
					.el-badge__content.is-fixed {
						left: 60px;
						right: auto;
						transform: none;
						color: $--seller-primary;
						border-color: $--seller-primary;
						background-color: #FFFFFF;
					}
				}
			}
		}
	}
</style>
